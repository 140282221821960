@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-neutral;
}

:root {
  --transform-speed: 250ms;
}

html {
  scroll-behavior: smooth;
}
section {
  scroll-margin-top: 4rem;
}
