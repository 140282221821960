.about-text {
  animation-duration: 3s;
  animation-name: slidein;
}

@keyframes slidein {
  from {
    margin-left: 100%;
    width: 100%;
  }

  to {
    width: 100%;
  }
}
